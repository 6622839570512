import CTABanner from "components/common/footer-banners/CTABanner";
import G2BadgeSlider from "components/common/G2BadgeSlider";
import InfoSlider from "components/common/InfoSlider";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "linaria";
import React from "react";

import G2Carousel from "./G2Carousel";
import G2Header from "./G2Header";

const imageClass = css`
  max-width: 698px;
`;

export default function G2Page() {
  const renderContent = data => {
    const slides = [
      {
        quote: "Rhombus is on another level!",
        paragraph:
          "To locate and disseminate incident video took hours and involved the IT dept. Now I can locate the video and send it to concerned parties via a secure link in minutes.",
        reviewer: "Courtney F.",
        title: "President & CEO",
        image: data.courtneyF,
      },
      {
        quote: "Rhombus has changed the way we think of camera systems.",
        paragraph:
          "We are replacing our CCTV cameras with cloud-based Rhombus cameras so our teams can view footage from anywhere on the planet!",
        reviewer: "Brian H.",
        title: "Advisory Board",
        image: data.BrianH,
      },
      {
        quote:
          "A great camera system for remote and lean IT/Security departments.",
        paragraph: [
          "1. Ease of installation and setup",
          "2. Ease of account/user/role management",
          "3. Ease of use by end users",
          "4. LOW LOW LOW network overhead...",
        ],
        reviewer: "Daniel H.",
        title: "Information Technology Manager",
        image: data.DanielH,
      },
      {
        quote: "Rhombus is awesome!",
        paragraph:
          "Rhombus makes all clients and partners feel like family while providing the best technology to meet our security needs.",
        reviewer: "Dr. Lamar D.",
        title: "CEO",
        image: data.LamarD,
      },
      {
        quote: "Great cameras and system for a small business price.",
        paragraph:
          "I like how the cameras are a self-contained NVR. The cameras communicate directly to the cloud, and there is no need for a device in house that needs to be maintained.",
        reviewer: "Matthew L.",
        title: "Technology Support Specialist",
        image: data.matthewL,
      },
      {
        quote: "Excellent Camera Solution.",
        paragraph:
          "We have been able to lean heavily on our Rhombus system to monitor our sales floor & exterior of our building with high resolution.",
        reviewer: "Massimiliano D.",
        title: "Paralegal Intern",
        image: data.massimD,
      },
      {
        quote: "Great solution and super easy to manage!",
        paragraph:
          "Mounting the cameras is also very easy and the options the cameras provide for coverage are top notch! I would highly recommend these cameras to anyone!",
        reviewer: "Rob G.",
        title: "Sales Director",
        image: data.robG,
      },
      {
        quote: "Great cloud-based video surveillance solution.",
        paragraph:
          "This solution requires no infrastructure and works over low bandwidth connections.",
        reviewer: "Dan B.",
        title: "COO of Curated Technology Services",
        image: data.danB,
      },
    ];
    const infoSlides = [
      {
        tabTitle: "AI Analytics",
        title: "Stay in the Know with Real-Time, AI-Powered Smart Alerts",
        description:
          "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
        image: data.infoSlide1,
      },
      {
        tabTitle: "Smart Cameras",
        title: "Protect What Matters Most with Powerful Camera Solutions",
        description:
          "Rhombus suite of dome, fisheye, and bullet cameras deliver exceptional performance and reliability for businesses of all sizes. Capture, detect, and manage security events from a single pane of glass.",
        image: data.infoSlide2,
      },
      {
        tabTitle: "Environmental Monitoring",
        title: "Create Healthier Spaces and Manage Climate-Controlled Areas",
        description:
          "Continuously monitor air quality, detect vape, and manage temperature, humidity, and more with integrated IoT sensors. Track and gain insight into environmental conditions with real-time alerts and synchronized video.",
        image: data.infoSlide3,
      },
      {
        tabTitle: "Access Control",
        title: "Unify Building Management Like Never Before",
        description:
          "Pair seamlessly with the Rhombus Platform for all-in-one security at scale. Manage all aspects of your spaces from a single pane of glass to streamline operations, eliminate complexity, and improve safety.",
        image: data.infoSlide4,
      },
      {
        tabTitle: "Alarm Monitoring",
        title:
          "Protect Your Spaces Around the Clock with Professional Monitoring",
        description:
          "Smart alarms, real agents. Protect your buildings 24/7/365 with modern alarm monitoring that combines automatic security alerts with live monitoring services.",
        image: data.infoSlide5,
      },
    ];

    return (
      <>
        <G2Header />
        <SectionContainer
          style={{
            background: "var(--white)",
            flexDirection: "column",
            alignItems: "center",
            gap: "5rem",
          }}
        >
          <G2BadgeSlider />
          <SectionInner>
            <FlexEndColumn>
              <TextContainer style={{ gap: "1rem" }}>
                <TitleMed>
                  A Reliable, Easy-to-Implement Solution for Organizations
                </TitleMed>
                <MainParagraph style={{ maxWidth: "552px" }}>
                  G2’s Summer Report is in and Rhombus maintains the #1 in
                  Physical Security position. From an easy-to-use platform,
                  plug-and-play setup, and world-class support, it’s no surprise
                  that customers love using Rhombus to protect their spaces.
                </MainParagraph>
              </TextContainer>
            </FlexEndColumn>
            <SectionCol>
              <StaticImage
                alt="g2 badges for Best Usability, Leader and Best Results for Winter 2024"
                className={imageClass}
                loading="eager"
                src="./img/best-results-min.png"
                placeholder="blurred"
              />
            </SectionCol>
          </SectionInner>
        </SectionContainer>
        <G2Carousel slides={slides} />
        <InfoSlider data={infoSlides} title="An All-in-One Platform" />
        <CTABanner />
      </>
    );
  };
  const GET_IMAGES = graphql`
    query {
      courtneyF: file(
        relativePath: {
          eq: "components/landing-pages/g2/img/slides/courtney-f-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      BrianH: file(
        relativePath: {
          eq: "components/landing-pages/g2/img/slides/brian-h-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      DanielH: file(
        relativePath: {
          eq: "components/landing-pages/g2/img/slides/daniel-h-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      LamarD: file(
        relativePath: {
          eq: "components/landing-pages/g2/img/slides/lamar-d-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      matthewL: file(
        relativePath: {
          eq: "components/landing-pages/g2/img/slides/matthew-l-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      massimD: file(
        relativePath: {
          eq: "components/landing-pages/g2/img/slides/massim-d-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      robG: file(
        relativePath: {
          eq: "components/landing-pages/g2/img/slides/rob-g-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      danB: file(
        relativePath: {
          eq: "components/landing-pages/g2/img/slides/dan-b-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/landing-pages/search/img/smart-cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/e2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: {
          eq: "components/landing-pages/search/img/access-control-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: {
          eq: "components/alarms/updated/img/alert-verification-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
