import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  MainParagraph,
  PageTitle,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "linaria";
import React from "react";

const sectionClass = css`
  display: grid;
  position: relative;
  background: linear-gradient(270deg, var(--teal-500) 0%, var(--blue-500) 100%);
`;

const contentClass = css`
  max-width: var(--max-width);
  width: 100%;
  display: flex;
  padding: 4rem 20px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1075px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 600px) {
    padding: 4rem 0;
    padding-bottom: 0;
  }
`;

const leftClass = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  gap: 1.25rem;
  @media (max-width: 1075px) {
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;

const textClass = css`
  max-width: 535px;
  width: 100%;
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  @media (max-width: 1075px) {
    width: unset;
    padding-bottom: 65px;
  }
`;

const imageClass = css`
  max-width: 535px !important;
`;

export default function G2Header() {
  return (
    <section className={sectionClass}>
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className={contentClass}>
          <div className={leftClass}>
            <StaticImage
              alt="g2 badges for Best Usability, Leader and Best Results for Spring 2023"
              className={imageClass}
              loading="eager"
              src="./img/g2-badges.png"
              placeholder="none"
            />
            <div className={textClass}>
              <PageTitle>Rhombus Ranks #1 in Physical Security on G2</PageTitle>
              <MainParagraph>
                Big news! Based on popularity and user satisfaction, Rhombus has
                been ranked #1 in Physical Security by G2 in their Summer
                Report.
              </MainParagraph>
            </div>
          </div>
          <FormWrapper width="552px" title="Start a Free Trial">
            <RhombusForm formId="6f4405c2-70e5-45a8-b139-cad010d9e4e3" />
          </FormWrapper>
        </div>
      </div>
    </section>
  );
}
