import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import Helmet from "react-helmet";
import G2Page from "components/landing-pages/g2/G2Page";

export default function G2() {
  return (
    <GlobalLayout>
      <Helmet>
        <title>Rhombus - Rated #1 in Physical Security on G2</title>
        <meta
          name="description"
          content="“Rhombus has changed the way we think of camera systems.” Read why customers love Rhombus."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <G2Page />
    </GlobalLayout>
  );
}
