import React from "react";
import { css } from "linaria";
import { StaticImage } from "gatsby-plugin-image";

import G2SwiperCarousel from "./G2SwiperCarousel";
import G2Slide from "./G2Slide";
import TextLink from "components/common/TextLink";
import PageContent from "components/page/PageContent";
import { FancyTitleMed } from "components/rhombus-UI/theme/typography";

const sectionClass = css`
  padding: 108px 0px 83px;
  background: var(--nuetral-100);

  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 2px;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  @media (max-width: 700px) {
    padding: 60px 0px 25px;
  }
`;

const arrowLinkContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 83px;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 700px) {
    margin-top: 25px;
  }

  p {
    display: inline-block;
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
  }
`;

const arrowClass = css`
  height: 12px;
  padding-left: 12px;
  padding-right: 6px;
  transition: padding 0.3s ease-in-out;
  .${arrowLinkContainer}:hover & {
    padding-left: 20px;
  }
`;

export default function G2Carousel({ slides }) {
  return (
    <section className={sectionClass}>
      <PageContent>
        <FancyTitleMed style={{ marginBottom: "4rem" }}>
          See Why Industry Leaders Choose Rhombus
        </FancyTitleMed>
        <G2SwiperCarousel>
          {slides.map((slide, i) => {
            return <G2Slide slide={slide} key={i} />;
          })}
        </G2SwiperCarousel>
        <TextLink
          href="https://www.g2.com/products/rhombus-systems/reviews/"
          target="_blank"
          className={arrowLinkContainer}
        >
          <p>Read G2 Reviews</p>
          <div className={arrowClass}>
            <StaticImage
              src="../../common/img/blue-arrow.png"
              alt="arrow"
              style={{ width: "7px" }}
              placeholder="blurred"
            />
          </div>
        </TextLink>
      </PageContent>
    </section>
  );
}
